.cancel-btn h6 {
  color: #4789b2;
}

.report-btn {
  display: inline-flex;
  color: #fff;
  align-content: center;
}

.report-btn .spinning-btn {
  height: 20px;
  width: 20px;
  align-self: center;
}

.report-btn .report-icon {
  height: 25px;
  width: 25px;
  align-self: center;
}

#file-regular,
#search-plus-regular {
  fill: #fff;
}

#Oval {
  fill: var(--primary);
}

.detail-report-btn {
  margin: 0;
  padding: 0;
}

.detail-report-btn .btn-spin {
  height: 40px;
  width: 40px;
  animation: button-spin 2s infinite linear;
  align-self: center;
}

.detail-report-btn .btn-spin #icons {
  fill: #4789b2;
}

.detail-report-btn #file-regular,
.detail-report-btn #search-plus-regular {
  fill: #4789b2;
}

.detail-report-btn #Oval {
  fill: #fff;
}

.lang-select-btn {
  margin: 0px auto;
}

.showhide-btn {
  color: #0046ad;
  position: absolute;
  right: 0;
  padding: 4px 4px;
  margin-top: 2px;
  cursor: pointer;
}

.info-button {
  background-image: url("../img/icons/icon-info.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.nav-icon-plus {
  background-image: url("../img/icons/icon-plus.svg");
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.nav-icon-search {
  background-image: url("../img/icons/icon-search.svg");
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.search {
  padding: 10px;
  padding-bottom: 0;
  background-color: #004f9f;
  border-radius: 2px;
}

.input-clear-btn {
  position: absolute;
  cursor: pointer;
  background-image: url("../img/icons/icon-clear-input-field.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  height: 23px;
  width: 23px;
  right: 10px;
  bottom: 45px;
}

.LoaderButton,
h6 {
  display: inline-flex;
  margin: auto;
  padding: 7px;
}

.spinning-btn {
  margin-right: 7px;
  animation: button-spin 2s infinite linear;
  background-image: url("../img/icons/icon-sync.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
}

@keyframes button-spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.chart-btn {
  color: #333;
  text-transform: none;
  letter-spacing: 0;
  font-size: 0.97rem;
}

button.note-btn {
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 10px;
}

button.note-btn h6 {
  text-transform: none;
  letter-spacing: 0;
  font-size: 1rem;
}
