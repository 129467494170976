.search-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 1rem;
  text-align: center;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.search-content h3 {
  color: #1d2a43;
  font-weight: bold;
  margin-bottom: 2rem;
}

.search-content .table-responsive {
  padding: 10px;
}

.search-content .pagination {
  justify-content: center;
  flex-wrap: wrap;
}

.warning-row {
  color: var(--danger);
}

.result-table tr {
  align-items: center;
}

.result-modal {
  background-color: #eff4f8;
  min-width: 100%;
  min-height: 100%;
  border-radius: 0;
  margin: 0;
  padding: 0 2.5rem;
}

.result-modal .modal-content {
  border: none;
}

.result-modal-btn {
  border: none;
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1rem;
}

.result-modal-btn p {
  margin-bottom: 0.5rem;
}

.result-modal .modal-header,
.modal-body {
  background-color: #eff4f8;
  border: none;
}

.result-modal .modal-header {
  display: inline;
}

.result-modal h6 {
  text-align: left;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1.07rem;
  padding: 0;
}

.result-modal p {
  margin-bottom: 0;
}

.result-modal p.list-group-item-text {
  margin: 0;
  padding: 0;
}

.result-pagination {
  margin: auto;
}

.details-list-group {
  margin: auto;
}

.condition-icons {
  color: #9fc3de;
}

.transmitter-setting-card .flex-row {
  background-color: rgba(159, 195, 222, 0.15);
}
