.timeline-container {
  padding-top: 1rem;
}

ul.timeline {
  list-style-type: none;
  position: relative;
  overflow: auto;
}

.fa-ul {
  margin-left: 0;
}

ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 3px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li.no-icon:before {
  content: " ";
  display: inline-block;
  background: var(--primary);
  border-radius: 50%;
  position: absolute;
  margin-top: 7px;
  left: 0px;
  width: 12px;
  height: 12px;
  z-index: 400;
}

.fa-li {
  margin-top: 3px;
  left: 0;
  font-size: 15px;
  width: 15px;
  z-index: 500;
}

.fas.fa-user {
  color: var(--primary);
}
.fas.fa-exclamation-triangle {
  color: var(--danger);
}

ul.timeline > li.info:before {
  background: var(--primary);
}

ul.timeline > li.danger:before {
  background: var(--danger);
}
ul.timeline > li.success:before {
  background: var(--success);
}

.pagination-row {
  width: 100%;
  margin: 0;
}

.pagination-col {
  padding: 5px 0;
}

.log-pagination {
  display: flex;
  justify-content: flex-end;
}

.log-pagination ul {
  margin-bottom: 0;
}

.log-pagination-link {
  border: none;
}
