.devices-owned-btn,
.device-owned-close-btn {
  padding: 0;
  margin-bottom: 0.15rem;
  text-transform: none;
  text-decoration: none;
  letter-spacing: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: none;
  vertical-align: middle;
}

.devices-owned-btn .btn-link:disabled,
.btn-link.disabled {
  color: #212529;
  opacity: 1;
  border: none;
}

.margin-left-10px {
  margin-left: 10px;
}
.bottom-divider {
  border-bottom: 1px solid #9fc3de;
}

.transmitter-conf-table {
  border-bottom: 1px solid #9fc3de;
}

.transmitter-conf-table tbody td {
  min-width: 150px;
  text-align: left;
  border: none;
  padding: 0.5rem;
  vertical-align: middle;
}

.transmitter-conf-row {
  padding: 0.5rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  white-space: nowrap;
}

.connected-devices-table {
  border: none;
}

.connected-devices-table tbody td {
  min-width: 270px;
  text-align: left;
  border: none;
  padding: 0.5rem;
  vertical-align: middle;
}

.connected-devices-row {
  padding: 0.5rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  white-space: nowrap;
  border-bottom: 1px solid #9fc3de;
}
