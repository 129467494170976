.icon-spin {
  position: relative;
  background-image: url("../img/icons/icon-spin.svg");
  height: 200px;
  width: 200px;
  margin: auto;
}

.loader-div {
  text-align: center;
  margin: auto;
  padding: 50%;
}
.Loader .spinning {
  display: inline-block;
  vertical-align: middle;
  animation: loader-spin 2s infinite linear;
  color: #004f9f;
}
@keyframes loader-spin {
  from {
    transform: scale(10) rotate(0deg);
  }
  to {
    transform: scale(10) rotate(-360deg);
  }
}

.detail-item,
.detail-title {
  border-bottom: 1px solid #9fc3de;
}
.confirmation-dialog {
  background-color: #fff;
  box-shadow: 3px 3px 15px 0px #868889;
  padding: 2.5rem;
  border-radius: 0.3rem;
}

.confirmation-dialog .modal-content {
  border: none;
}

.confirmation-dialog .modal-body {
  background-color: #fff;
}

.status-indicator {
  display: inline-flex;
  margin: auto;
  width: 1rem;
  height: 1rem;
}

#status-indicator-danger {
  color: var(--danger);
}
#status-indicator-online {
  color: var(--success);
}

#status-indicator-offline {
  color: var(--secondary);
}

.custom-caret {
  color: #9fc3de;
}

.transmitter-setting-card {
  padding: 0.2rem;
  background-color: rgb(236, 243, 248, 0.5);
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #9fc3de;
}

.transmitter-status-div {
  margin-right: 5px;
}
