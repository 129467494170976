.home-container {
  display: inline-flex;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-container h3 {
  color: #005d96;
}

.main-navigation {
  padding: 0;
  width: 10em;
  height: 100%;
}

.main-content {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.home-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0;
}

.home-text {
  width: 100%;
  margin: 0;
  padding: 2rem;
}

.home-content-filler {
  background-image: url("../img/VILPE_Sense_app_taustakuva_light.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  width: 100%;
  height: 100%;
}
