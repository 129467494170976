.form-card {
  top: 5rem;
  padding: 2rem;
  text-align: center;
  color: "#005D96";
  margin: auto;
}

.form-card h3 {
  color: #1d2a43;
  font-weight: bold;
  margin: 0;
}

.login-form {
  padding: 2rem;
}

.user-invitation-modal {
  padding: 1.5rem !important;
}

.add-user-dialog {
  background-color: #fff;
  box-shadow: 3px 3px 15px 0px #868889;
  padding: 2.5rem;
  border-radius: 0.3rem;
}
.add-user-dialog .modal-content {
  background-color: #fff;
  border: none;
}

@media (min-width: 576px) {
  .form-card {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .form-card {
    max-width: 650px;
  }
}
@media (min-width: 992px) {
  .form-card {
    max-width: 650px;
  }
}
@media (min-width: 1200px) {
  .form-card {
    max-width: 650px;
  }
}

div.img-logo {
  display: flex;
  text-align: center;
  background-image: url("../img/icons/VILPE_Sense_logo_horizontal_rgb.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
  width: 300px;
  margin: auto;
  margin-bottom: 1rem;
}
div.img-email {
  display: flex;
  text-align: center;
  background-image: url("../img/icons/illustration-use-email.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 125px;
  margin: auto;
}
div.img-password {
  display: flex;
  text-align: center;
  background-image: url("../img/icons/illustration-user-key.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 125px;
  margin: auto;
}

button.login-pw-forgot {
  margin: 0;
  padding: 0;
  text-transform: none;
  letter-spacing: 0;
}

.search-form {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.search-form button {
  min-width: 75px;
}
