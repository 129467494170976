.main-navigation {
  position: relative;
}

.navbar {
  padding: 0;
  text-align: center;
  text-transform: none;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: bold;
  height: 100%;
}

.navbar small {
  margin: auto;
  text-transform: none;
  letter-spacing: 0;
  font-size: 0.8rem;
  color: var(--dark);
  padding: 0;
}

.navbar-brand {
  margin: auto;
  padding: 0;
}

.navbar-logo {
  background-image: url("../img/icons/VILPE_Sense_logo_vertical_rgb.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 60px;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 0;
}

.main-menu-nav {
  margin-top: 2em;
  width: 100%;
  height: 100%;
  border: none;
}

.nav-item {
  border: none;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
  color: var(--secondary);
}

.nav-link,
.nav-link:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  color: #0046ad;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 1;
}

.nav-item-icon {
  height: 30px;
  width: 30px;
  fill: #0046ad;
  opacity: 0.5;
}

.nav-item:hover {
  background-color: #f2f2f2;
  border: none;
  border-radius: none;
  color: var(--dark);
}

.nav-item:hover .nav-item-icon {
  opacity: 0.75;
  color: #0046ad;
}

.nav-item.active {
  background-color: #e6e6e6;
  border-bottom: #0046ad 4px solid;
  border-top: #0046ad 4px solid;
  margin-bottom: 0;
  margin-top: 0;
  color: #000;
}

.nav-item.active .nav-item-icon {
  opacity: 1;
}

.nav-item-logout {
  margin-top: 100%;
  margin-bottom: 100%;
  opacity: 1;
}
